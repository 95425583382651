body {
  margin: 0;
  font-family: "Mark Pro";
  overflow-x: hidden;

  * {
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
  }

  *:focus,
  *:active {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    /* -webkit-user-select: text;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; */
    -moz-user-select: text;
    user-select: auto;
  }

  ul {
    margin-left: 0;
    padding-left: 0;
  }

  input[type="password"] {
    font-family: Verdana, sans-serif !important;
    letter-spacing: 1px;
  }
  input[type="text"],
  input,
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .pageContainer {
    width: 100%;
    max-width: 1160px;
    margin: 0px auto;
    box-sizing: border-box;
    @include small-device {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    @include design-device {
      padding-left: 10px;
      padding-right: 10px;
    }
    @include regular-device {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .buttonWhite {
    background-color: white;
    border-color: $light-gray-color;
    border-width: 2px;
    color: $secondary-color;
    font-weight: bold;

    &:hover {
      background-color: inherit;
    }
  }

  // TOASTIFY

  .Toastify__toast {
    border-radius: 14px;
    padding: 0 15px !important;
    box-shadow: none;
    top: 10px;
    min-height: 56px;
    &-container {
      min-width: 344px;
      @include tiny-device {
        margin-top: 30px;
        max-width: 300px;
        min-width: 200px;
        left: 50%;
        transform: translate(-50%, -50%);
        top: $big-gutter;
      }
    }

    &--success {
      background-color: $toast-success-text;
      span {
        color: #fff;
      }
    }
    &--warning {
      background-color: $toast-error;
      span {
        color: $toast-error-text !important;
        font-weight: bold;
      }
    }
  }

  // MATERIAL UI

  .MuiCheckbox-colorPrimary.Mui-checked:hover,
  .MuiFilledInput-root:hover {
    background-color: transparent;
  }

  .MuiInputBase-input:focus {
    border-color: $soft-blue;
  }

  .MuiTypography-h5 {
    @include tiny-device {
      font-size: $font-regular-big;
    }
  }

  .MuiTypography-h6 {
    @include tiny-device {
      font-size: $font-small;
    }
  }

  .MuiDialog-paper {
    @include tiny-device {
      margin: 45px 10px;
      width: 100%;
    }
  }

  .MuiCheckbox-root.Mui-focusVisible {
    color: $soft-blue;
  }

  .MuiButton-root.Mui-focusVisible {
    border-color: $soft-blue;
  }

  .MuiFormLabel-root.Mui-error {
    color: $error-border-color;

    + div input {
      background-color: $error-bg-color;
      border-color: $error-border-color;
      color: $error-text-color;
    }
    + div textarea {
      background-color: $error-bg-color;
      border-color: $error-border-color;
      color: $error-text-color;
    }
  }
  .MuiFilledInput-root.Mui-disabled {
    background-color: transparent;
  }

  // GENERAL CLASSSES

  .flexAlignCenter {
    display: flex;
    align-items: center;
  }

  .iconRightPadding {
    padding-right: 12px;
  }

  .overflowUnset {
    overflow: unset;
  }

  // ICONS

  .arrowDownIcon {
    transform: rotate(-90deg);
  }

  .arrowLeftIcon {
    position: absolute;
    margin-left: -25px;
    margin-top: -15px;
    cursor: pointer;
    color: #000;
    @include tiny-device {
      margin-left: 0;
      margin-top: 0;
      left: 10px;
      top: 10px;
    }
  }

  .searchIcon {
    position: absolute;
    z-index: 2;
    right: 14px;
  }

  .passwordShowHideIcon {
    position: absolute;
    z-index: 2;
    right: 14px;
    cursor: pointer;
  }

  .greenTickBigIcon {
    display: block;
    margin: auto;
  }

  .closeIcon {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9;
  }

  .loginIcon {
    margin-left: 10px;
  }

  .closeIconWhite {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
  }

  .handIcon {
    width: 30px;
    height: 30px;
  }
}
