$secondary-color: #004469;
$light-background-color: #f8f8fb;
$disable-color: #cad3da;
$blue-color: #1AAADC;
$primary-border-color: #ebeef0;
$light-blue: #80B4CB;
$dark-blue: #15617d;
$soft-blue: #5DB7DE;
$moderate-cyan: #43A9D6;
$dark-gray-color: #383C52;
$gray-color: #ACB6BD;
$light-gray-color: #EBEEF0;
$white-gray-color: #DEE4E6;
$icewind-gray: #E7EBED;
$shadow-black: rgba(0, 0, 0, 0.05);

$input-color: $secondary-color;
$checkbox-label-color: #606375;
$separator-color: #f5f5f6;
$dark-separator-color: #adc2ca;
$disabled-color: #d7d8dc;
$placeholder-color: #c3c4cb;

$error-border-color: #ffabab;
$error-bg-color: rgba(255, 171, 171, 0.07);
$error-text-color: #f37268;

$toast-success: #ebf9ef;
$toast-success-text: #3ac562;
$toast-error: #ffeeee;
$toast-error-text: #ff5959;

$tiny-device-width: 639px;
$small-device-width: 959px;
$regular-device-width: 1199px;
$design-device-width: 1139px;
$big-device-width: 1599px;
$large-device-width: 2100px;

$tiny-device-height: 500px;

$small-gutter: 10px;
$regular-gutter: 20px;
$big-gutter: 30px;
$bigger-gutter: 40px;
$large-gutter: 50px;

$font-tiny: 9px;
$font-smaller: 12px;
$font-small: 14px;
$font-regular: 16px;
$font-regular-big: 18px;
$font-medium-big: 20px;
$font-big: 24px;
$font-bigger: 32px;
$font-largest: 48px;

$radius-regular: 8px;
$radius-large: 12px;