@import "../../Theme/scss/variables";
@import "../../Theme/scss/mixins";
@import "../../Theme/scss/typo";
@import "../../Theme/scss/common";

.announcementcard {
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    background: #f5f5f6;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 0px 16px;
    cursor: pointer;

    @include small-device {
      height: 90px;
    }
    .text {
      width: 100%;
      padding: 0px 16px;
      h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.4px;
        color: #004469;
        padding: 0;
        margin: 0;
      }
      span {
        font-weight: 450;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.3px;
        color: #606375;
      }
    }
    & > .icon > svg {
      width: 24px;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translate(-50%, -60%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%);
  }
}
.announcement-popup {
  cursor: pointer;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  position: fixed;
  top: 50%;
  left: 50%;
  line-height: 0;
  transform: translate(-50%, -50%);
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  z-index: 1000;
  overflow: auto;
  max-width: 50vw;
  width: 100%;
  height: auto;

  img{
    width: 100%;
  }

  @media screen and (max-width: 860px) {
    max-width: 90vw;
  }

  &--closing {
    animation: fadeOut 500ms;
  }
}
.announcement-popup.fade-out {
  animation-name: fadeOut;
  animation-duration: 0.5s;
}
.announcement-popup__header {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.announcement-popup__description {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  text-align: justify;
  line-height: 1.6;
  padding: 0 1rem;
}


.announcement-popup__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    padding-bottom: 30%;
  }
}


.announcement-popup__link {
  font-size: 1.2rem;
  text-decoration: underline;
  color: black;
  margin-bottom: 1rem;
}

.announcement-popup__close {
  cursor: pointer;
  font-size: 1.2rem;
  background-color: transparent;
  border: 2px solid black;
  color: black;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  outline: none;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 10px;
}

.app__button {
  width: 100%;
  letter-spacing: -0.4px;
  text-align: center;
}
  

.errormessage {
  margin-top: 4;
  margin-bottom: 10;
  margin-left: 16;
  max-width: 80%;
  &__errorText{
    font-family: 'Mark Pro';
    font-size: 13;
    color: red;
    text-align: 'left',
  }
}

#app__footer {
  background: #ffffff;
  border-top: 1px solid #f2f4f6;
  padding-top: 60px;
  padding-bottom: 20px;
  @include small-device {
    padding-top: 35px;
  }
  .ibbLogo {
    justify-content: center;
    @include small-device {
      display: none;
    }
    text-align: center;
    @include regular-device {
      text-align: center;
    }
  }
  .tcmb-belbim-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    @include small-device {
      display: none;
    }
    img:nth-child(1) {
      margin-right: 6px;
    }
    img {
      vertical-align: middle;
      margin-top: 16px;
      margin-bottom: 12px;
      object-fit: contain;
    }
  }
  .copyright {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #afb1ba;
    p {
      margin: 0;
      padding: 0;
    }
    @include small-device {
      display: none;
    }
  }
  .mobile__bottom {
    display: none;
    @include small-device {
      display: block;
    }
  }
  .footer__row1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 450px;
    margin: 0px auto 6px auto;
    justify-content: space-between;
    svg {
      @include small-device {
        max-width: 50%;
      }
    }
    @include small-device {
      display: none !important;
    }
    .link {
      cursor: pointer;
    }
  }
}

.app__footer {
  &__link {
    &__list {
      list-style: none;
      margin-top: -1px;
      @include small-device {
        margin: 0;
        margin-left: 16px;
      }
      li {
        padding-bottom: 17px;
        a {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: #606375;
        }
      }
    }
  }
}

.footer-bottom-ul {
  margin-bottom: 23px;
  margin-top: 0;
  margin-left: 30px;
  @include small-device {
    margin-left: 16px;
  }
  & > .linkList {
    display: flex;
    align-items: center;
  }
  .ibb__belbim__horizontal {
    margin-top: 32px;
    display: block;
  }
  .mobile__icon__tcmb__belbim {
    display: flex;
    max-width: 450px;
    margin: 0px auto;
    img {
      max-width: 50%;
      height: auto;
      object-fit: contain;
    }
  }
  .footer__mobile__copyrow {
    display: flex;
    align-items: flex-end;
    margin-top: 15px;
    @media (min-width: 450px) and (max-width: 900px) {
      width: 100%;
      justify-content: center;
    }
  }
  .copyright__mobile__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: #afb1ba;
    margin-top: 20px;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    @media (min-width: 450px) and (max-width: 900px) {
      text-align: center;
    }
  }
  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #606375;
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
  }

  span.footer-blue {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1581be;
  }
}

.version__number {
  font-size: 12px;
  color: #b2b4bd;
  padding: 0px;
  margin: 0;
  @media (max-width: 850px) {
    margin-inline: 1rem;
  }

  margin-top: 5px;
}

.mobile__bottom {
  ul {
    margin-left: 0px !important;
  }
  .tcmb__img {
    margin-left: -10px;
  }
}

#app__header {
  background-color: $secondary-color;
  padding-top: 16px;
  padding-bottom: 16px;
  position: sticky;
  top: 0;
  z-index: 1000;
  @include small-device {
    background-color: #fff;
    padding-top: 32px;
    padding-bottom: 27px;
    border-bottom: solid 2px #f2f4f6;
  }
  .belbim__logo {
    display: block;
    width: 9rem;
    height: 2rem;
    @include small-device {
      display: none;
    }
  }
  .belbim__mobil__logo {
    display: none;
    margin-left: 20px;
    @include small-device {
      display: block;
      width: 10.5rem;
      height: 2.5rem;
    }
  }
  .mobil-menu {
    display: none;
    @include small-device {
      display: block;
      margin-left: 20px;
    }
  }
  .sb-search {
    position: absolute;
    right: 100%;
    margin-top: 0px;
    width: 0%;
    min-width: 60px;
    height: 52px;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    transition: width 0.3s;
    -webkit-backface-visibility: hidden;
    margin-right: -10px;
    @include small-device {
      display: none;
      right: auto;
      left: 90px;
      margin-right: 0;
      margin-top: -2px;
    }
    @media (max-width: 768px) {
      display: none;
      right: auto;
      margin-left: 5px;
      margin-right: 0;
      margin-top: -2px;
    }
  }
  .sb-search-open {
    width: 328px;
    @include small-device {
      display: block;
      margin-right: 20px;
      left: 0;
      width: 128px;
    }
    .sb-search-input {
      background: transparent;
      border: 2px solid #67dbff;
      border-radius: 12px;
      color: #fff;
      @include small-device {
        color: #333;
      }
    }
  }
  .sb-search-input {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    outline: none;
    width: 100%;
    height: 52px;
    margin: 0;
    z-index: 10;
    padding: 20px 50px 20px 20px;
    font-family: "Mark Pro";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: #383c52;
    background: transparent;
    @include small-device {
      color: #333;
    }
  }
  .sb-search-submit {
    background: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    color: transparent;
    border: none;
    outline: none;
    z-index: -1;
  }
  .sb-icon-search {
    z-index: 10;
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
  .sb-icon-search,
  .sb-search-submit {
    width: 52px;
    height: 52px;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    margin: 0;
    line-height: 66px;
    text-align: center;
    cursor: pointer;
  }
  .mobile__menu {
    position: fixed;
    width: 100%;
    height: calc(100vh - 87px);
    background: linear-gradient(315deg, #67dbff 0%, #1581be 100%);
    top: 87px;
    z-index: 1000;
    display: none;
    ul {
      padding: 0px 1rem;
      li {
        margin-right: 31px;
        a {
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          letter-spacing: -0.2px;
          color: #ffffff;
          padding: 22px 11px 19px 0px;
          border-bottom: 1px solid rgba(216, 216, 216, 0.15);
          display: block;
        }
        a.online {
          border-bottom: 0;
          background: #278fb4;
          border-radius: 8px;
          margin-top: 20px;
          width: fit-content;
          padding: 20px 15px 17px 14px;
        }
      }
    }
    .tcmb-belbim-logo-header {
      img:nth-child(1) {
        margin-right: 6px;
        margin-left: 16px;
      }
      img {
        vertical-align: middle;
        margin-top: 16px;
        margin-bottom: 12px;
        margin-left: 16px;
      }
    }
    .copyright-header {
      margin-left: 16px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: -0.3px;
      color: #ffffff;
      p {
        margin: 0;
        padding: 0;
      }
    }
  }
  .active__mobil__menu {
    display: none;
    @include small-device {
      display: block;
    }
  }
}

.app__header {
  &__mobileSearchIcon {
    display: none;
    @media (max-width: 768px) {
      display: block;
      width: 24px;
      height: 24px;
      padding: 4px;
      box-sizing: content-box;
      border-radius: 6px;
      // margin-right: 16px;
      border: solid 2px #fff;
      & > svg {
        width: 24px;
        height: 24px;
      }
    }
    &__active {
      border: solid 2px #f2f4f6;
    }
  }
  &__fixed {
    @include small-device {
      position: fixed !important;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1000;
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;
    width: 100%;
  }
  @media (min-width: 960px) and (max-width: 1170px) {
    &__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    ul {
      display: flex;
      flex-direction: row;
      padding: 0;
      margin: 0;
      margin-top: 12px;
      @include small-device {
        display: none;
      }
    }
    li {
      list-style: none;
      margin-right: 31px;
      a {
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.2px;
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
  &__right {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  &__lang {
    background: #15617d;
    border-radius: 180px;
    padding: 11px 11px 9px 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.1px;
    color: #ffffff;
    margin-left: 20px;
    @include small-device {
      display: block;
      margin-left: 0px !important;
    }
    @media (max-width: 768px) {
      display: block;
      margin-left: 0px;
    }
  }
  &__button {
    &__link {
      border: 0px solid #ebebed;
      padding: 20px 23px 20px 23px;
      height: 60px;
      background: #15617d;
      mix-blend-mode: normal;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.1px;
      color: #ffffff;
      box-sizing: border-box;
      margin-left: 48px;
      @include small-device {
        display: none;
      }
    }
  }
}
@media (max-width: 768px) {
  .app__header__right {
    .sb-search {
      display: block; // mobil cihazlarda arama butonunu göster
      position: absolute;
      right: 60px; // mobil menü düğmesinin soluna yerleştirin
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.app__header {
  .mobil-menu {
    display: none;
    @include small-device {
      display: block;
      margin-right: 20px;
      margin-left: 20px;
    }
  }
}

.mobile__input__search {
  display: none;
  position: relative;
  @include small-device {
    display: block;
    position: sticky;
    top: 105px;
    left: 0;
    padding: 16px 20px;
    z-index: 999;
    background-color: #fff;
  }

  input {
    font-family: "Mark Pro" !important;
    @include small-device {
      width: 100%;
      height: 50px;
      border: solid 2px #5db7de;
      border-radius: 12px;
      padding: 0px 16px;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: -0.4px;
      color: #004469;
    }
    &::placeholder {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: -0.4px;
      color: #004469;
    }
  }

  .icon__container {
    position: absolute;
    right: 16px;
    top: 13px;
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
}

@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";
.input {
  background: #ffffff;
  border: 2px solid #ebebed;
  position: relative;
  border-radius: 12px;
  display: flex;
  height: 60px;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  &__error {
    border-color: $error-text-color;
  }
  &__close {
    position: absolute;
    left: 170px;
    top:15px;
  }
  &__label {
    font-family: "Mark Pro";
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: -0.3px;
    color: #888a97;
    padding-top: 9px;
    padding-left: 16px;
  }
  &__prefix{
    font-family: "Mark Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    letter-spacing: -0.4px;
    color: #383c52;
    margin-top: 4px;
    margin-left: 16px;
    padding-right: -16px;
  }
  &__inputField {
    border: none;
    text-align: left;
    padding-left: 16px;
    margin-top: 4px;
    font-family: "Mark Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    letter-spacing: -0.4px;
    color: #383c52;
    -webkit-appearance: none;
    width: 100%;
  }
  &__toolTip {
    position: absolute;
    left: 102%;
    bottom: 19%;
    @include tiny-device {
      left: 102%;
      bottom: 17.5%;
    }
  }
  &__popover {
    position: absolute;
    left: 85.5%;
    bottom: 70%;
    @include tiny-device {
      left: 50%;
      bottom: 70%;
    }
    &__text {
      position: absolute;
      left: 87%;
      bottom: 90%;
      width: 190px;
      font-family: "Mark Pro";
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.3px;
      color: #ffffff;
      @include tiny-device {
        left: 52%;
        bottom: 90%;
      }
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#loading {
    flex-direction: column;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999999;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .loading_image {
      width: 90px;
      height: 90px;
    }
  }
  
@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";
.maskInput {
  background: #ffffff;
  border: 2px solid #ebebed;
  position: relative;
  border-radius: 12px;
  display: flex;
  height: 60px;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  &__error{
    border-color: $error-text-color;
  }
  &__close {
    position: absolute;
    left: 170px;
    top: 15px;
  }
  &__label {
    font-family: "Mark Pro";
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: -0.3px;
    color: #888a97;
    padding-top: 9px;
    padding-left: 16px;
  }
  &__prefix {
    font-family: "Mark Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    letter-spacing: -0.4px;
    color: #383c52;
    margin-top: 4px;
    margin-left: 16px;
    padding-right: -16px;
  }
  &__inputField {
    border: none;
    text-align: left;
    padding-left: 16px;
    margin-top: 4px;
    font-family: "Mark Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    letter-spacing: -0.4px;
    color: #383c52;
    -webkit-appearance: none;
  }
}

.news__box {
  text-decoration: none;
  cursor: pointer;
  &__image {
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    > img {
      width: 100%;
      max-height: 207px;
      vertical-align: middle;
      box-sizing: border-box;
      overflow: hidden;
      object-fit: cover;
    }
  }
  &__content {
    background: #ffffff;
    border: 1px solid #f5f5f6;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 20px;
    padding-top: 14px;
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      letter-spacing: -0.3px;
      color: #888a97;
    }
    h3 {
      font-size: 18px;
      line-height: 23px;
      display: flex;
      align-items: center;
      letter-spacing: -0.5px;
      color: #383c52;
      margin-top: 16px;
      margin-bottom: 12px;
    }
    p {
      font-weight: 450;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.4px;
      color: #383c52;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}

.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: "";
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.press__colorbox {
    width: 273px;
    height: 165px;
    background-color: #e7ebed;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 42px;
    box-sizing: border-box;
    position: relative;
    @media screen and (max-width: 620px) {
      justify-content: center;
      height: 120px;
      padding: 12px 20px;
      margin-bottom: 32px;
    }
    p {
      padding: 0;
      margin: 0;
    }
    &__codes {
    }
    &__code {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.3px;
      color: #ffffff;
    //   @media screen and (max-width: 620px) {
    //     font-weight: 500;
    //     font-size: 13px;
    //     line-height: 16px;
    //   }
    }
    &__text {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.3px;
      color: #ffffff;
      margin-top: 26px !important;
    //   @media screen and (max-width: 620px) {
    //     position: absolute;
    //     top:80px;
    //     color: #383C52;
    //     width: 100%;  
    //     text-align: left;
    //   }
    }
  }
  
.press__image {
  width: 273px;
  min-height: 165px;
  height: fit-content;
  background-color: #e7ebed;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px;
  box-sizing: border-box;
  @include small-device {
    width: 100%;
    min-height: 120px;
    padding: 12px 20px;
  }
  &__image {
    height: 100%;
    // max-height: 62px;
    min-height: 100%;
    // cursor: pointer;
    @include small-device {
      width: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
      max-height: 32px;
      > svg {
        width: 100%;
      }
    }
  }
  &__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #383c52;
    @include small-device {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      padding: 0px;
      margin: 0px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &__link {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    align-items: center;
    gap: 4px;
    & > svg {
      width: 24px;
      height: 24px;
    }
    &__text {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.3px;
      color: $dark-gray-color;
      padding: 0;
      margin: 0;
      @include small-device {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        margin-right: 5px;
      }
    }
  }
}

@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";
.searchField {
  @include tiny-device {
    min-height: unset;
    padding-right: $big-gutter;
    padding-left: $big-gutter;
  }

  @include tiny-device {
    padding-right: $regular-gutter;
    padding-left: $regular-gutter;
  }
  display: flex;
  position: relative;
  width: 100%;
  margin: 50px;
  border-radius: 12px;
  background-color: white;
  border-color: #ebeef0;
  font-size: 11px;
  & > div {
    min-height: 71.6px;
  }
}

@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";
.selectList{
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
}

.selectList__error .selectList__control {
    border-color: $error-text-color;
    border-width: 2px;
}

.tab__box {
  width: 273px;
  height: 88px;
  background-color: #F7F8F9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  box-sizing: border-box;
  position: relative;
  margin:8px;
  cursor: pointer;
  text-align: center;
  @media screen and (max-width: 620px) {
    justify-content: center;
    height: 120px;
    padding: 12px 20px;
    margin-bottom: 32px;
  }
  &__text {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.5px;
    color: #004469;
    margin-top: 26px !important;
  }
}

@import "../../Theme/scss/variables";
@import "../../Theme/scss/_mixins";
#onlineTransactions {
    .onlineTransactions{
        margin-bottom: 81px;
        margin-top: 100px;
        &__title{
            font-family: 'Mark Pro';
            font-weight: 700;
            font-size: 18px;
            line-height: 23px;
            letter-spacing: -0.5px;
            color: #B9C2CA;
            margin-top: -40px;
        }
        &__container{
            display: flex;
            flex-direction: row;
            @include tiny-device {
                flex-direction: column;
            }
        }
    }
}
  
